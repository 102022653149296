import type { LoungePairSettings } from './com.loungepair.buy'
import type { HomeLinkSettings } from './com.loungepair.homelink'
import type { PayRewardsSettings } from './com.loungepair.payrewards'
import type { PointHacksSettings } from './com.loungepair.pointhacks'
import type { RegalSettings } from './com.loungepair.regal'
import type { TenantSettings } from './tenants.server'

export function isLoungePairTenantSettings(
  settings: TenantSettings,
): settings is LoungePairSettings {
  return settings.tenant === 'loungepair'
}

export function isPayRewardsTenantSettings(
  settings: TenantSettings,
): settings is PayRewardsSettings {
  return settings.tenant === 'payrewards'
}

export function isPointHacksTenantSettings(
  settings: TenantSettings,
): settings is PointHacksSettings {
  return settings.tenant === 'pointhacks'
}

export function isHomeLinkTenantSettings(
  settings: TenantSettings,
): settings is HomeLinkSettings {
  return settings.tenant === 'homelink'
}

export function isRegalTenantSettings(
  settings: TenantSettings,
): settings is RegalSettings {
  return settings.tenant === 'regal'
}
